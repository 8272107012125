<template>
    <div
        :class="{
            tabs: true,
            [tabType]: true,
            scrollable,
            dark: isDark,
            'with-bottom-border': bottomBorder,
            'with-top-border': topBorder,
            'have-scroll': tabsHaveScroll,
            'truncate-text': truncateText,
        }"
    >
        <div v-if="isPresto && usePrestoTabs" class="tabs-menu-presto" @click="togglePrestoMenu">
            <span>
                <slot name="tab-prefix" :tab="activePrestoMenuItem" />
                {{ activePrestoMenuItem.text }}
                <slot name="tab-suffix" :tab="activePrestoMenuItem" />
            </span>
            <SvgIcon
                iconId="arrow_down"
                :style="{
                    transform: `rotate(${!showPrestoMenu ? '0' : '180'}deg)`,
                }"
                class="icon icon-size-very-small"
            />
        </div>
        <ul
            :class="{
                'tabs-menu': true,
                'presto-mode': isPresto && usePrestoTabs,
                'presto-tabs-visible': showPrestoMenu,
                ['align-' + align]: true,
            }"
            ref="tabsMenu"
        >
            <li
                v-for="(tab, index) in tabs"
                :key="tab.key + index"
                :class="{
                    active: tab.key === active.key && !tab.disableHighlight,
                    first: index === 0,
                    last: index === tabs.length - 1,
                    ['no-grow']: !tab.text && !tab.disableNoGrow,
                }"
                :data-test-id="`tabs-${tab.key}`"
                :ref="`tab-${tab.key}`"
                class="tabs-selector"
                @click="handleTabClick(tab)"
            >
                <div class="tab-item">
                    <div class="tab-item-border">
                        <template v-if="tab.componentName">
                            <component :is="tab.componentName" v-bind="tab.props" />
                        </template>
                        <template v-else>
                            <div v-if="tab.iconId" class="tab-icon">
                                <div :class="['om-icon-wrapper', tab.iconClass]">
                                    <SvgIcon class="icon" :class="tab.iconClass" :iconId="tab.iconId" />
                                </div>
                            </div>
                            <slot name="tab-prefix" :tab="tab" />
                            <span v-if="tab.text" class="tab-text">{{ tab.text }}</span>
                            <slot name="tab-suffix" :tab="tab" />
                        </template>
                    </div>
                </div>
            </li>
        </ul>
        <slot />
    </div>
</template>

<script>
import { deviceType, helper } from '@agi.packages/core';

const TAB_TYPES = ['square', 'round', 'text'];

export default {
    name: 'Tabs',
    props: {
        tabs: {
            type: Array[Object],
            required: true,
        },
        active: {
            type: Object,
            default() {
                return {};
            },
        },
        tabType: {
            type: String,
            default: 'square',
            validator: (type) => TAB_TYPES.includes(type),
        },
        align: {
            type: String,
            default: 'center',
        },
        scrollable: Boolean,
        bottomBorder: Boolean,
        topBorder: {
            type: Boolean,
            default: true,
        },
        usePrestoTabs: Boolean,
        tabChangeTrackingName: String,
        truncateText: Boolean,
        isDark: Boolean,
    },
    data() {
        return {
            tabsHaveScroll: false,
            isPresto: deviceType.isPresto(),
            showPrestoMenu: false,
        };
    },
    computed: {
        activePrestoMenuItem() {
            const activeItem = this.tabs.filter((item) => item.key === this.active.key);

            return activeItem[0];
        },
    },
    mounted() {
        if (this.scrollable) {
            this.setTabsScrollState();
            this.setScrollPosition();
            window.addEventListener('resize', this.setTabsScrollState);
        }
    },
    beforeDestroy() {
        if (this.scrollable) {
            window.removeEventListener('resize', this.setTabsScrollState);
        }
    },
    methods: {
        setTabsScrollState() {
            this.tabsHaveScroll = this.$refs.tabsMenu.scrollWidth > this.$refs.tabsMenu.clientWidth;
        },
        togglePrestoMenu() {
            if (this.usePrestoTabs) {
                this.showPrestoMenu = !this.showPrestoMenu;
            }
        },
        handleTabClick(tab) {
            this.$emit('select', tab);
            this.setScrollPosition();

            if (this.tabChangeTrackingName) {
                this.$gtm.query({
                    event: this.$gtm.makeValidEventName(this.tabChangeTrackingName + tab.key),
                });
            }

            const metrika = helper.getObjectField(tab, 'data.metrika');
            if (metrika) {
                this.$gtm.query({
                    event: 'tab_click',
                    metrika,
                });
            }

            if (this.isPresto) {
                this.showPrestoMenu = false;
            }
        },
        setScrollPosition() {
            if (!this.tabsHaveScroll) {
                return;
            }

            this.$nextTick(() => {
                const [tab] = this.$refs[`tab-${this.active.key}`] ?? [];

                if (tab) {
                    tab.scrollIntoView({ block: 'nearest', inline: 'center' });
                }
            });
        },
    },
};
</script>

<style scoped lang="scss">
.tabs {
    .tabs-menu {
        display: flex;
        list-style: none;
        user-select: none;

        &.presto-mode {
            display: none;

            &.presto-tabs-visible {
                display: block;
            }

            .tabs-selector {
                border-bottom: 1px solid $border-color;

                &:last-of-type {
                    border-bottom: none;
                }
            }
        }

        .tabs-selector {
            &.no-grow {
                width: auto;
                padding-left: 12px;
                padding-right: 12px;
            }
        }
    }

    .tabs-menu-presto {
        border-bottom: 1px solid $border-color;
        padding: 4px 0 4px 16px;
        font-size: 18px;
        font-weight: bold;

        svg {
            float: right;
            margin: 7px 7px 0;
        }
    }

    .tabs-selector {
        width: 100%;
        cursor: pointer;

        .tab-item {
            height: 100%;

            &-border {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;

                @include maxbasic {
                    @include only_mini {
                        flex-wrap: wrap;
                    }
                }

                .tab-icon {
                    display: flex;
                    align-items: center;

                    & + .tab-text {
                        margin-left: 8px;
                    }

                    .om-icon-wrapper {
                        @include only_mini {
                            position: relative;
                            top: -2px;
                        }

                        @include all_but_mini {
                            display: flex;
                        }

                        @extend %icon-sizes;
                    }
                }
            }
        }
    }

    &.square {
        .tabs-selector {
            @extend %body-normal-font-400;
            padding: 10px 16px;
            background: $light-grey-bg;
            border: 1px solid $border-color;
            border-top: none;

            &:not(.last) {
                border-right: none;
            }

            @include only_mini {
                padding: 10px 5px;
            }

            &.active {
                background: $white-bg;
                border-bottom: none;

                .tab-text {
                    @extend %body-normal-font-700;
                }

                &.first {
                    border-left: none;
                }

                &.last {
                    border-right: none;
                }
            }
        }
    }

    &.round {
        .tabs-selector {
            .tab-item {
                border-radius: 200px;
                border: 1px solid $casino-pill-border;
                background: white;
                color: $casino-pill-text-color;
                height: 34px;

                &-border {
                    .tab-icon + .tab-text {
                        margin-left: 4px;
                    }
                    background: white;
                    padding: 6px 16px;
                    border: 1px solid transparent;
                    border-radius: 200px;
                    width: 100%;
                    height: 100%;
                }
            }

            margin-right: 8px;
            flex: 0;

            .tab-text {
                @extend %small-details-font-400;
            }

            &.last {
                .tab-item {
                    margin-right: 0;
                }
            }

            &.active {
                .tab-item {
                    background-color: #ddfaa5;
                    border-color: #9ce800;
                    border-width: 1px;
                    &-border {
                        background-color: #ddfaa5;
                        border-color: #9ce800;
                        border-width: 1px;
                    }
                }
            }
        }
    }

    &.text {
        .tabs-menu {
            padding: 3px 0;
            justify-content: center;

            &.align-left {
                justify-content: flex-start;
            }

            &.align-right {
                justify-content: flex-end;
            }
        }

        .tabs-selector {
            width: auto;
            padding: 11px 12px;
            background: none;
            border: none;

            .tab-text {
                @extend %body-normal-font-700;
            }

            &.active .tab-text {
                @extend %body-normal-font-700;
                color: $tabs-selector-color;
            }
        }

        &:not(.scrollable) {
            .tabs-menu {
                flex-wrap: wrap;
            }
        }
    }

    &.with-bottom-border {
        .tabs-menu {
            border-bottom: 1px solid $border-color;
        }
    }

    &.with-top-border.square {
        > .tabs-menu > .tabs-selector {
            border-top: 1px solid $border-color;
        }
    }

    &.scrollable {
        .tabs-menu {
            overflow: scroll;

            @include hide_scrollbar;
        }

        .tabs-selector {
            white-space: nowrap;
        }

        &.have-scroll .tabs-menu {
            justify-content: unset;
        }
    }

    &.truncate-text.square {
        @include all_but_mini {
            .tab-item,
            .tabs-selector {
                overflow: hidden;

                .tab-text {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }

        @mixin presto-width($sizes) {
            @each $size in $sizes {
                @media screen and (max-width: $size) {
                    max-width: calc(calc(#{$size}/ 2) - 8px); // minus padding
                }
            }
        }

        @include only_mini_feature_phone {
            $sizes: 280px, 270px, 260px, 250px, 240px, 230px, 220px, 210px, 200px;
            .tabs-selector {
                @include presto-width($sizes);
                overflow: hidden;
            }
        }
    }

    &.dark {
        background: map-get($bg-neutral, 'bg-lighten-1');
        .tabs-menu {
            background: transparent;
            padding: 0;
            border: 0;
            display: flex;

            .tabs-selector {
                background: transparent;
                color: map-get($bg-neutral, 'bg-lighten-2');
                border: none;
                flex-grow: 1;
                border-bottom: 2px solid transparent;

                .tab-item .tab-text {
                    @extend %body-small-font-700;
                    text-transform: uppercase;
                }

                &.active {
                    color: $light-green;
                    border-bottom: 2px solid $light-green;
                    background: none;

                    .tab-item .tab-icon .svg-icon {
                        fill: $light-green;
                    }
                }

                .tab-item {
                    .tab-icon .svg-icon {
                        fill: map-get($bg-neutral, 'bg-lighten-2');
                    }
                }
            }

            &.presto-mode {
                display: none;
                border: none;
                color: map-get($bg-neutral, 'bg-lighten-2');

                .tabs-selector {
                    padding-top: 9px;
                    padding-bottom: 9px;
                    border-bottom: 1px solid #44494c;

                    &:last-of-type {
                        border-bottom: none;
                    }

                    @include only_mini {
                        .tab-item-border {
                            justify-content: center;
                        }
                    }
                }

                &.presto-tabs-visible {
                    display: block;
                }
            }
        }
        .tabs-menu-presto {
            color: $light-green;
            border: 1px solid #44494c;

            svg {
                fill: $light-green;
            }
        }
    }
}
</style>
