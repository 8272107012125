<template>
    <div v-if="matchId && isStatisticOpen">
        <iframe title="Widget" class="genius-sport" :src="serviceUrl"></iframe>
        <div class="disclaimer">
            {{ $t('ui.eventPage.disclaimer.geniusSport') }}
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { getter } from '@/modules/platform';
import { getter as translationsGetter } from '@/store/modules/translations';

const languageToCultureMap = {
    fr: 'fr-FR',
    pt: 'pt-pt',
    sw: 'sw',
};

export default {
    name: 'GeniusSport',
    props: {
        matchId: {
            type: Number,
        },
    },
    computed: {
        ...mapGetters({
            brandPreference: getter.GET_BRAND_PREFERENCE,
            selectedLanguage: translationsGetter.GET_SELECTED_LANGUAGE,
        }),
        ...mapState({
            isStatisticOpen: (state) => state.sport.isStatisticOpen,
        }),
        serviceUrl() {
            const { betGeniusProviderHost, betGeniusProductName, betGeniusLayout, betGeniusWidget } = this.brandPreference;

            if (!betGeniusProviderHost || !betGeniusProductName || !this.matchId) {
                throw new Error('The required parameters are missing to build the URL.');
            }

            const url = new URL('/multisportgametracker', betGeniusProviderHost);
            const params = new URLSearchParams({
                productName: betGeniusProductName,
                fixtureId: this.matchId,
            });

            if (languageToCultureMap[this.selectedLanguage]) {
                params.append('culture', languageToCultureMap[this.selectedLanguage]);
            }
            if (betGeniusWidget) {
                params.append('widget', betGeniusWidget);
            }
            if (betGeniusLayout && !betGeniusWidget) {
                params.append('layout', betGeniusLayout);
            }

            url.search = params.toString();
            return url.toString();
        },
    },
};
</script>

<style scoped lang="scss">
.genius-sport {
    background: transparent;
    width: 100%;
    height: 320px;
    overflow: hidden;
    border: none;
}
</style>
