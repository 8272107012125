<template>
    <div class="on-boarding" v-if="isVisible">
        <div v-if="isRecentlySignedUp" class="account-created">
            <h3>{{ $t('ui.onBoarding.title') }}</h3>
            <renderer :input="$t('ui.onBoarding.text', { brand })" />
        </div>
        <div class="action-list">
            <router-link v-if="!isEverDeposited" :to="{ path: '/deposit?t=on' }" class="item">
                <div class="table">
                    <div class="row-cell">
                        <h3>{{ $t('ui.onBoarding.depositTitle') }}</h3>
                        <p>
                            <span>{{ $t('ui.onBoarding.depositTextFirst') }}</span>
                            <renderer :input="$t('ui.onBoarding.depositTextSecond', { balance })" />
                        </p>
                    </div>
                    <div class="row-cell">
                        <SvgIcon iconId="arrow_right" class="icon" />
                    </div>
                </div>
            </router-link>
            <router-link :to="{ path: '/learn-how-to-bet' }" class="item">
                <div class="table">
                    <div class="row-cell">
                        <h3>{{ $t('ui.onBoarding.bettingTitle') }}</h3>
                        <p>
                            <span>{{ $t('ui.onBoarding.bettingText') }}</span>
                            <span>{{ $t('ui.onBoarding.learnHow') }}</span>
                        </p>
                    </div>
                    <div class="row-cell">
                        <SvgIcon iconId="arrow_right" class="icon" />
                    </div>
                </div>
            </router-link>
            <router-link
                v-if="isBonus"
                :to="{ path: $t(`project.ui.onBoarding.${isBoost ? 'boostLink' : 'bonusLink'}`) + '?t=on' }"
                class="item"
            >
                <div class="table">
                    <div class="row-cell">
                        <h3>{{ $t('project.ui.onBoarding.bonusTitle', { bonusPercentage }) }}</h3>
                        <p>
                            <span>{{ $t('project.ui.onBoarding.bonusTextFirst') }}</span>
                            <renderer :input="$t('project.ui.onBoarding.bonusTextSecond', { winBonus })" />
                        </p>
                    </div>
                    <div class="row-cell">
                        <SvgIcon iconId="arrow_right" class="icon" />
                    </div>
                </div>
            </router-link>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { auth, getter as platformGetter } from '@agi.packages/platform';
import { betslip } from '@agi.packages/sport';

import { getter as generalGetter } from '@/store/store'; // define package for notifications BP-16141
import { userState } from '@agi.packages/core';

export default {
    name: 'OnBoarding',
    computed: {
        ...mapState({
            brand: (state) => state.platform.settings.brand.brand,
            firstBetSlip: (state) => state.platform.settings.preference.first_bet,
            firstLogIn: (state) => state.platform.auth.firstLogIn,
            userId: (state) => state.platform.settings.user.userUuid,
            bonusPerLeg: (state) => state.platform.settings.bonus.perLeg,
        }),
        ...mapGetters({
            balance: auth.getter.GET_BALANCE,
            currentUserStatus: platformGetter.GET_CURRENT_USER_STATUS,
            notifications: generalGetter.GET_NOTIFICATION_TYPES, // define package for notifications BP-16141
            isAuthenticated: auth.getter.IS_AUTHENTICATED,
            settings: platformGetter.GET_SETTINGS,
            bonusPercentage: betslip.getter.GET_MAX_BONUS_PERCENTAGE,
            countryCodeIs: platformGetter.COUNTRY_CODE_IS,
        }),
        isBoost() {
            return this.countryCodeIs.KE;
        },
        winBonus() {
            return this.$t(`ui.common.${this.isBoost ? 'plainWinBoost' : 'plainWinBonus'}`);
        },
        isEverDeposited() {
            return this.currentUserStatus[userState.EVER_DEPOSITED];
        },
        isRecentlySignedUp() {
            return this.firstLogIn && !this.isEverDeposited;
        },
        isVisible() {
            return this.isAuthenticated && this.userId && (!this.isEverDeposited || !this.firstBetSlip);
        },
        isBonus() {
            return Array.isArray(this.bonusPerLeg) && this.bonusPerLeg.length > 0;
        },
    },
};
</script>

<style lang="scss" scoped>
.scrollable-content:has(.on-boarding:first-child) .on-boarding {
    margin-top: 10px;
}

.on-boarding {
    margin: 0 10px 10px 10px;

    .account-created {
        padding: 10px;
        text-align: center;

        h3 {
            text-transform: uppercase;
            color: $betpawa-green;
            margin: 0.5em 0;
        }
    }

    .action-list {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        @include only_mini {
            row-gap: 0;
        }
    }

    .action-list .item {
        display: block;
        padding: 10px;
        background-color: $onboarding-action-background;
        text-transform: uppercase;
        @include only_mini {
            margin-top: 10px;
        }

        @include maxmain {
            font-weight: bold;
            padding: 5px;
        }

        .row-cell {
            width: 100%;
            vertical-align: middle;
        }

        &:hover {
            color: inherit;
        }

        h3 {
            letter-spacing: -1px;
            font-weight: 800;
            font-size: 1.2em;
            margin: 0 0 0.2em 0;
            line-height: 0.8em;

            @include maxmain {
                font-weight: bold;
                font-size: 18px;
                line-height: 21px;
                margin: 0.2em 0;
                letter-spacing: -0.5px;
            }
        }

        p {
            color: $onboarding-p-text-color;
            font-size: 14px;
            font-weight: normal;
            letter-spacing: -0.5px;

            ::v-deep b {
                color: $onboarding-p-text-color-bold;
            }

            @include maxmain {
                text-transform: none;
                font-size: 18px;
                line-height: 21px;
                font-weight: normal;
            }

            @include oldoldschool {
                span {
                    display: block;
                }
            }
        }

        svg {
            width: 15px;
            height: 15px;
            fill: $grey-text;
            @include maxmain {
                display: none;
            }
        }
    }
}
</style>
