import Vue from 'vue';
import Vuex from 'vuex';
import core, { helper } from '@agi.packages/core';
import payment, { mutation as paymentMutation } from '@agi.packages/payment';
import sportStore, { betslip, betStatus, betType, endpoints as sportEndpoints, myBetsType, sport } from '@agi.packages/sport';
import platformStore, {
    action as platformAction,
    auth,
    getter as platformGetter,
    messaging,
    mutation as platformMutation,
} from '@agi.packages/platform';

import { MENUS } from '@/js/sheetdump';
import { toolbarIconType } from '@/js/casino-const';

import SnapshotToStorage from './plugins/SnapshotToStorage';
import translations, { mutation as translationsMutation } from './modules/translations';
import casinoStore, { mutation as casinoMutation, state as casinoState } from './modules/casino';

Vue.use(Vuex);

const IS_DEV = env.VUE_APP_ENV !== 'production';

export const mutation = {
    ADD_NOTIFICATION: 'addNotification',
    CLEAR_NOTIFICATIONS: 'removeNotifications',
    CLOSE_TOP_MENU: 'closeTopMenu',
    SET_COUNTRIES: 'setCountries',
    SET_CURRENT_CATEGORY: 'setCurrentCategory',
    SET_TOURNAMENTS: 'setTournaments',
    TOGGLE_LEFT_MENU_COUNTRY: 'toggleLeftMenuCountry',
    TOGGLE_LEFT_MENU_OTHER: 'toggleLeftMenuOther',
    TOGGLE_NOTIFICATION: 'toggleNotification',
    UPDATE_TERMS_DECLINE_STATUS: 'updateTermsDeclineStatus',
    TOGGLE_BETSLIP_STATE: 'toggleBetslipState',
};

export const action = {
    ACCEPT_TERMS: 'platform/AcceptTerms',
    GET_CATEGORIES_LIST: 'getCategoriesList',
    GET_CATEGORY_ACTIVE_REGIONS: 'getCategoryActiveRegions',
    LOADING: 'loading',
    NOTIFY: 'Notify',
    SET_BETSLIP_STATE: 'setBetslipState',
    SET_SIDEBAR_STATE: 'setSidebarState',
    TOGGLE_SIDEBAR: 'toggleSidebar',
    TOGGLE_BETSLIP_STATE: 'toggleBetslipState',
};

export const getter = {
    GET_SORTED_CATEGORIES: 'getSortedCategories',
    GET_CHIPS: 'getChips',
    GET_CURRENT_CATEGORY: 'getCurrentCategory',
    GET_CURRENT_CATEGORY_ID: 'getCurrentCategoryId',
    GET_JACKPOT_POOL: 'getJackpotPool',
    GET_MENU_ITEM_BY_BETSLIP: 'getMenuItemByBetslip',
    GET_MY_BETS_MENU: 'getMyBetsMenu',
    GET_NOTIFICATION_TYPES: 'getNotificationTypes',
    GET_QUICK_MAIN_MENU: 'getQuickMainMenu',
    GET_UPCOMING_VIRTUAL_ROUND_INDEX: 'getUpcomingVirtualRoundIndex',
    IS_VIRTUAL_SPORT: 'isVirtualSport',
};

const snapshotToStorage = new SnapshotToStorage({
    isDev: IS_DEV,
    immediate(storeMutation) {
        switch (storeMutation.type) {
            case translationsMutation.SET_LANGUAGE:
                return true;
        }
        return false;
    },
    filter(storeMutation) {
        switch (storeMutation.type) {
            case translationsMutation.SET_LANGUAGE:
            case mutation.SET_CURRENT_CATEGORY:
            case mutation.UPDATE_TERMS_DECLINE_STATUS:
            case platformMutation.SET_CONFIG:
            case platformMutation.SET_AFFILIATE_ID:
            case auth.mutation.SET_OTP_LIMITS:
            case platformMutation.SET_AGI_SETTINGS:
            case platformMutation.SET_USER_STATE:
            case platformMutation.SET_IS_NEED_TO_SEND_PAYMENT_COMPONENT_VISIBILITY_EVENT:
            case platformMutation.SET_CAMPAIGN_ELIGIBILITY:
            case platformMutation.SET_SURVEY:
            case messaging.mutation.SET_ONSITE_MESSAGES_AND_COUNT:
            case messaging.mutation.SET_MESSAGES:
            case auth.mutation.SET_PHONE_NUMBER:
            case auth.mutation.SET_OTP_TIMER:
            case auth.mutation.SET_AUTH:
            case auth.mutation.SET_OTP_ATTEMPTS:
            case auth.mutation.RESET_OTP_ATTEMPTS:
            case auth.mutation.SET_RESET_PASSWORD_TRACKING_ID:
            case sport.mutation.SET_JACKPOT_SELECTED:
            case sport.mutation.UPDATE_CASHOUT_POLLING:
            case betslip.mutation.CHANGE_BETSLIP_BET:
            case betslip.mutation.REMOVE_BETSLIP_BET:
            case betslip.mutation.REMOVE_ALL_BETSLIP_BETS:
            case betslip.mutation.SET_BETSLIP_STAKE:
            case betslip.mutation.RESET_BETSLIP_STAKE:
            case betslip.mutation.RESET_BETSLIP_STATUS:
            case betslip.mutation.SET_BETSLIP_ACCEPT_ANY_PRICE:
            case betslip.mutation.SET_VIRTUAL_BETSLIP_SEASON:
            case betslip.mutation.RESET_VIRTUAL_BETSLIP_SEASON:
            case betslip.mutation.UPDATE_SELECTED_PRICES:
            case betslip.mutation.ADD_BETSLIP_BET:
            case betslip.mutation.SET_BETSLIP_BETS:
            case paymentMutation.SET_FIRST_DEPOSIT_DATA:
            case paymentMutation.RESERVE_DEPOSIT_ID:
            case paymentMutation.SET_DEPOSIT_COMPONENT_IS_VISIBLE:
            case paymentMutation.RESET_DEPOSIT:
            case sport.mutation.SET_SAVED_EVENT_FILTERS:
            case casinoMutation.SET_GAMES_SORT_BY:
            case casinoMutation.SET_FAVOURITE_GAMES_SORT_BY:
            case casinoMutation.SET_GAMES:
            case casinoMutation.SET_CASINO_LAYOUT:
                return true;
        }
        return false;
    },
    reducer(state) {
        return {
            sport: {
                betslip: {
                    selectedEventId: state.sport.betslip.selectedEventId,
                    selectedPriceId: state.sport.betslip.selectedPriceId,
                    regular: state.sport.betslip.regular,
                    virtual: state.sport.betslip.virtual,
                },
                jackpot: {
                    selected: state.sport.jackpot.selected,
                    uniqueTicketId: state.sport.jackpot.uniqueTicketId,
                },
                myBets: {
                    offerPolling: state.sport.myBets.offerPolling,
                },
                ui: {
                    savedEventFilters: state.sport.ui.savedEventFilters,
                },
            },
            currentCategoryId: state.currentCategoryId,
            platform: {
                config: state.platform.config,
                affiliate: state.platform.affiliate,
                settings: {
                    preference: {
                        first_bet: state.platform.settings.preference.first_bet || null,
                    },
                },
                content: {
                    isNeedToSendPaymentComponentVisibilityEvent: state.platform.content.isNeedToSendPaymentComponentVisibilityEvent,
                },
                auth: {
                    phoneNumber: state.platform.auth.phoneNumber,
                    otpTimer: state.platform.auth.otpTimer,
                    nextCheckBalanceTimestamp: state.platform.auth.nextCheckBalanceTimestamp,
                    accountBalance: state.platform.auth.accountBalance,
                    isBalanceReady: state.platform.auth.isBalanceReady,
                    otpAttempts: state.platform.auth.otpAttempts,
                    otpLimits: state.platform.auth.otpLimits,
                    nextOtpAttemptTimestamp: state.platform.auth.nextOtpAttemptTimestamp,
                    resetPasswordTrackingId: state.platform.auth.resetPasswordTrackingId,
                },
                campaignEligibility: state.platform.campaignEligibility,
                survey: state.platform.survey,
                messaging: {
                    nextCheckTimestamp: state.platform.messaging.nextCheckTimestamp,
                    notSeen: state.platform.messaging.notSeen,
                },
            },
            payment: {
                firstDeposit: state.payment.firstDeposit,
                /**
                 * @deprecated since v3.14.x - use `firstDeposit` instead.
                 */
                lastDepositProvider: state.payment.lastDepositProvider,
                reservedDeposit: state.payment.reservedDeposit,
                deposit: {
                    providers: {
                        isDepositComponentVisible: state.payment.deposit.providers.isDepositComponentVisible,
                    },
                },
            },
            translations: {
                language: state.translations.language,
                persist: state.translations.persist,
            },
            isTermsDeclined: state.isTermsDeclined || false,
            casino: {
                layout: state.casino.layout || toolbarIconType.TILE,
                games: state.casino.games,
                gamesSortBy: state.casino.gamesSortBy,
                favoriteGamesSortBy: state.casino.favoriteGamesSortBy,
            },
        };
    },
});

export default new Vuex.Store({
    state: {
        title: 'State',
        menus: MENUS,
        notification: {
            notifications: [],
            trigger: false,
        },
        countries: {},
        currentCategoryId: null,
        currentTournaments: [],
        ui: {
            liveRefreshRate: 3,
            sidebarOpen: false,
            betslipOpen: false,
            topMenuOpen: false,
            bottomMenuOpen: false,
            mobileSearchOpen: false,
            leftMenuExpandedCountries: [], // TODO: merge with menus state
            showLeftMenuOther: false, // TODO: merge with menus state,
        },
        showTopMenu: false,
        wazdanGames: {},
        gameLaunchError: null,
        casino: casinoState,
        isTermsDeclined: false,
    },
    getters: {
        getNotificationTypes: (state) => state.notification.notifications.map((item) => item.type),
        [getter.GET_MY_BETS_MENU]: ({ menus, platform }) => {
            return menus.myBets
                .map((menu) => ({ ...menu, text: Vue.$t(menu.tKey) }))
                .filter((menu) => !menu.enabler || helper.pathChecker(menu.enabler, platform.settings));
        },
        [getter.GET_MENU_ITEM_BY_BETSLIP]:
            (state, getters) =>
            ({ betslipType = '', status = '' }) => {
                const myBetsMenu = getters[getter.GET_MY_BETS_MENU];
                const defaultItem = myBetsMenu.find((item) => item.default);
                switch (betslipType) {
                    case betType.REAL:
                        if (status === betStatus.PENDING) {
                            return myBetsMenu.find((item) => item.key === myBetsType.PENDING) || defaultItem;
                        }
                        return myBetsMenu.find((item) => item.key === myBetsType.SETTLED) || defaultItem;
                    case betType.VIRTUAL:
                        return myBetsMenu.find((item) => item.key === myBetsType.VIRTUAL) || defaultItem;
                    case betType.JACKPOT:
                        return myBetsMenu.find((item) => item.key === myBetsType.JACKPOT) || defaultItem;
                }

                return defaultItem;
            },
        [getter.GET_SORTED_CATEGORIES]: (state, getters) => {
            const rawCategories = getters[platformGetter.GET_CATEGORIES];
            return [...rawCategories].sort((a, b) => a.priority - b.priority);
        },
        [getter.GET_CURRENT_CATEGORY_ID]: (state, getters) => {
            const stateCategoryId = state.currentCategoryId;
            const stateCategoryData = getters[getter.GET_SORTED_CATEGORIES].find(
                (category) => String(category.id) === String(stateCategoryId)
            );

            if (stateCategoryId && stateCategoryData) {
                return stateCategoryId;
            }

            return getters[getter.GET_SORTED_CATEGORIES][0]?.id;
        },
        [getter.GET_CURRENT_CATEGORY]: (state, getters) => {
            const currentCategoryId = getters[getter.GET_CURRENT_CATEGORY_ID];
            const categories = getters[getter.GET_SORTED_CATEGORIES];
            return categories.find((category) => String(category.id) === String(currentCategoryId)) || {};
        },
        [getter.GET_QUICK_MAIN_MENU]: (state) => {
            return state.menus.quickMainMenu;
        },
    },
    mutations: {
        [mutation.SET_CURRENT_CATEGORY](state, newValue) {
            state.currentCategoryId = newValue;
        },
        [mutation.SET_TOURNAMENTS](state, newValue) {
            state.currentTournaments = newValue;
        },
        [mutation.SET_COUNTRIES](state, newValue) {
            state.countries = newValue;
        },
        setBetslipState(state, value) {
            state.ui.betslipOpen = value;
        },
        [mutation.TOGGLE_BETSLIP_STATE](state) {
            state.ui.betslipOpen = !state.ui.betslipOpen;
        },
        toggleSidebar(state) {
            state.ui.sidebarOpen = !state.ui.sidebarOpen;
        },
        setSidebarState(state, value) {
            state.ui.sidebarOpen = value;
        },
        toggleMobileSearch(state) {
            state.ui.mobileSearchOpen = !state.ui.mobileSearchOpen;
            state.ui.topMenuOpen = false;
        },
        closeMobileSearch(state) {
            state.ui.mobileSearchOpen = false;
        },
        setTopMenuState(state, value) {
            state.ui.topMenuOpen = value;
        },
        setBottomMenuState(state, value) {
            state.ui.bottomMenuOpen = value;
        },
        [mutation.CLOSE_TOP_MENU](state) {
            state.ui.topMenuOpen = false;
        },

        [mutation.TOGGLE_LEFT_MENU_COUNTRY](state, countryId) {
            const expandedCountries = state.ui.leftMenuExpandedCountries;
            const index = expandedCountries.indexOf(countryId);
            if (index < 0) {
                Vue.set(expandedCountries, expandedCountries.length, countryId);
            } else {
                Vue.delete(expandedCountries, index);
            }
        },
        [mutation.TOGGLE_LEFT_MENU_OTHER](state) {
            state.ui.showLeftMenuOther = !state.ui.showLeftMenuOther;
        },
        [mutation.ADD_NOTIFICATION](state, { type, data, status = 'success', excludedRoutes = [] }) {
            state.notification.notifications.push({ type, data, status, excludedRoutes });
            if (data && data.trigger) {
                state.notification.trigger = true;
            }
        },
        [mutation.CLEAR_NOTIFICATIONS](state) {
            state.notification.notifications = [];
            state.notification.trigger = false;
        },
        [mutation.TOGGLE_NOTIFICATION](state) {
            state.notification.trigger = !state.notification.trigger;
        },
        [mutation.UPDATE_TERMS_DECLINE_STATUS](state, payload) {
            state.isTermsDeclined = payload;
        },
    },
    actions: {
        [action.SET_TURNSTILE]({ commit }, value) {
            commit(mutation.SET_TURNSTILE, value);
        },
        [action.GET_CATEGORY_ACTIVE_REGIONS]({ commit, rootGetters, dispatch }, categoryId) {
            const competitionIcons = rootGetters[platformGetter.GET_JURISDICTION_CONFIG].globalConfig.competitionIcons;

            function getCompetitionIconUrl({ id }) {
                if (id in competitionIcons) {
                    return competitionIcons[id];
                }

                return '';
            }

            return Vue.$http
                .get(sportEndpoints.pricing.getCategoryActiveRegions + `/${categoryId}`)
                .then(({ data }) => {
                    const { withRegions, onlyMeta } = data;
                    const { liveEventCount = 0, upcomingEventCount = 0, boostedEventCount = 0, regions = [] } = withRegions[0] ?? {};
                    const otherCategoriesLiveEventCount = onlyMeta.reduce((acc, { liveEventCount }) => acc + liveEventCount, 0);
                    const upcomingEventCountPerCategory = getEventTypeCountPerCategory(onlyMeta, 'upcomingEventCount');
                    const boostedEventCountPerCategory = getEventTypeCountPerCategory(onlyMeta, 'boostedEventCount');
                    const tournaments = [];

                    const sortedCountries = regions
                        .map((country) => {
                            const currentCountry = {
                                region: country.region.name,
                                regionSlug: country.region.slug,
                                parentRegionSlug: country.region.parentSlug,
                            };
                            const countryIcon = {
                                src: currentCountry.regionSlug ? `img/flags/${currentCountry.regionSlug}.png` : '',
                                altSrc: currentCountry.parentRegionSlug ? `img/flags/${currentCountry.parentRegionSlug}.png` : '',
                            };
                            let countryEventCount = 0;
                            let countryPriority = 0;
                            const currentCompetitions = [];
                            if (country.competitions.length) {
                                country.competitions.forEach(({ competition, eventCount }) => {
                                    const competitionData = {
                                        ...competition,
                                        eventCount: eventCount,
                                        icon: {
                                            src: getCompetitionIconUrl(competition) || countryIcon.src,
                                            altSrc: countryIcon.altSrc,
                                        },
                                    };
                                    if (countryPriority < competition.priority) {
                                        countryPriority = competition.priority;
                                    }
                                    if (competition.priority) {
                                        tournaments.push({
                                            ...competitionData,
                                            ...currentCountry,
                                        });
                                    }
                                    countryEventCount += eventCount;
                                    currentCompetitions.push(competitionData);
                                });
                            }
                            currentCompetitions.sort((a, b) => b.priority - a.priority);
                            return {
                                ...country,
                                icon: countryIcon,
                                eventCount: countryEventCount,
                                priority: countryPriority,
                                competitions: currentCompetitions,
                            };
                        })
                        .sort((a, b) => a.region.name.localeCompare(b.region.name));
                    const updatedTournaments = tournaments.flat().sort((a, b) => b.priority - a.priority);
                    commit(mutation.SET_TOURNAMENTS, updatedTournaments);
                    commit(mutation.SET_COUNTRIES, sortedCountries);
                    commit(sport.mutation.UPDATE_SPORTS, {
                        liveEventsCount: otherCategoriesLiveEventCount + liveEventCount,
                        boostedEventsCount: { ...boostedEventCountPerCategory, [categoryId]: boostedEventCount },
                        moreEvents: { ...upcomingEventCountPerCategory, [categoryId]: upcomingEventCount },
                    });
                })
                .catch((error) => console.error(`${action.GET_CATEGORY_ACTIVE_REGIONS} Response Error`, [error, categoryId]));
        },
        [action.TOGGLE_SIDEBAR]({ commit }) {
            commit('toggleSidebar');
        },
        [action.SET_SIDEBAR_STATE]({ commit }, value) {
            commit('setSidebarState', value);
        },
        [action.TOGGLE_BETSLIP_STATE]({ commit, dispatch }) {
            dispatch(betslip.action.SELECT_BETSLIP_TYPE);
            commit(mutation.TOGGLE_BETSLIP_STATE);
        },
        [action.SET_BETSLIP_STATE]({ commit, dispatch }, isOpen) {
            dispatch(betslip.action.SELECT_BETSLIP_TYPE);
            commit('setBetslipState', isOpen);
        },
        [action.NOTIFY]({ commit }, payload) {
            commit(mutation.ADD_NOTIFICATION, payload);
        },
        [action.ACCEPT_TERMS]: ({ dispatch }, payload) => {
            dispatch(platformAction.PUT_DERIVED_DATA, payload).then(() => {
                dispatch(platformAction.LOAD_AGI_SETTINGS);
            });
        },
    },
    modules: {
        core,
        platform: platformStore,
        sport: sportStore,
        payment,
        translations,
        casino: casinoStore,
    },
    strict: IS_DEV,
    plugins: [snapshotToStorage.plugin],
});

function getEventTypeCountPerCategory(metaData = [], eventType) {
    return metaData.reduce((acc, category) => ({ ...acc, [category.category.id]: category[eventType] }), {});
}
