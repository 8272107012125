<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div class="my-bets" v-sniffer="{ handler: onSnifferLoadMore, distance: 0, disabled: isPresto, debounceTimeout: 100 }">
        <Tabs class="my-bets-tabs" :tabs="myBetsMenu" :active="currentSelector" @select="setMenu">
            <div v-if="error" class="notify error">
                <renderer :input="error" />
            </div>
            <template v-else>
                <Tab :name="tabType.JACKPOT">
                    <!-- // TODO: replace JackpotBet to BetsList -->
                    <JackpotBet :hasMore="!isPreview" @load-more="loadMoreItems(tabType.JACKPOT)" />
                    <ViewAllButton
                        v-if="isPreview && jackpot.items && jackpot.items.length"
                        :section="currentSelector.name"
                        @click.native="viewAllButtonClick(currentSelector.name)"
                    />
                </Tab>
                <Tab :name="tabType.SETTLED">
                    <BetsList
                        :items="settled.items"
                        :hasMore="!isPreview && settled.hasMore"
                        type="settled"
                        @load-more="loadMoreItems(tabType.SETTLED)"
                    >
                        <template slot="empty"> {{ $t('ui.myBets.emptyData.settledBet') }}</template>
                    </BetsList>
                    <ViewAllButton
                        v-if="isPreview && settled.items && settled.items.length"
                        :section="currentSelector.name"
                        @click.native="viewAllButtonClick(currentSelector.name)"
                    />
                </Tab>
                <Tab :name="tabType.PENDING">
                    <BetsList
                        :is-outcome="false"
                        :items="pending.items"
                        :hasMore="!isPreview && pending.hasMore"
                        @load-more="loadMoreItems(tabType.PENDING)"
                        type="open"
                    >
                        <template v-slot:icon="props">
                            <SvgIcon
                                v-if="props.bet.cashoutInfo.cashoutable"
                                :title="$t('ui.cashout.cashout')"
                                class="icon-size-medium"
                                iconId="icon-cashoutable"
                            />
                        </template>
                        <template slot="empty"> {{ $t('ui.myBets.emptyData.openBet') }}</template>
                    </BetsList>
                    <ViewAllButton
                        v-if="isPreview && pending.items && pending.items.length"
                        :section="currentSelector.name"
                        @click.native="viewAllButtonClick(currentSelector.name)"
                    />
                </Tab>
                <Tab :name="tabType.VIRTUAL">
                    <BetsList
                        :items="virtual.items"
                        :hasMore="!isPreview && virtual.hasMore"
                        type="virtual"
                        @load-more="loadMoreItems(tabType.VIRTUAL)"
                    >
                        <template slot="empty"> {{ $t('ui.myBets.noBets.virtual') }} </template>
                    </BetsList>
                    <ViewAllButton
                        v-if="isPreview && virtual.items && virtual.items.length"
                        :section="currentSelector.name"
                        @click.native="viewAllButtonClick(currentSelector.name)"
                    />
                </Tab>
                <div class="spinner-wrapper" v-if="isGetMoreData">
                    <Spinner :listen="spinnerTriggers" class="align-top load-more-spinner" />
                </div>
            </template>
        </Tabs>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { sport, myBetsType } from '@agi.packages/sport';
import { Tab, Tabs } from '@agi.packages/core/components';
import { auth } from '@agi.packages/platform';
import { action, getter as generalGetter } from '@/store/store';

import BetsList from '../BetsList.vue';
import JackpotBet from '../JackpotBet.vue';
import ViewAllButton from './Betslip/ViewAllButton.vue';
import scrollSniffer from '@/js/directives/ScrollSniffer';
import { deviceType, getter as coreGetter } from '@agi.packages/core';
import { getObjectField } from '@/modules/core/utils/helper';

const REQUEST_ITEMS_PACE = {
    [myBetsType.PENDING]: 25,
    default: 12,
};

const DEFAULT_PREVIEW_ITEMS_PAGE = 5;

export default {
    name: 'MyBets',
    components: { JackpotBet, BetsList, ViewAllButton, Tabs, Tab },
    directives: {
        sniffer: scrollSniffer,
    },
    props: {
        isPreview: {
            type: Boolean,
            default: false,
        },
    },
    data: function () {
        return {
            tabType: myBetsType,
            currentSelector: {},
            offset: 0,
            offsetStep: 8,
            isRedirectToSettledAvailable: false,
            isPresto: deviceType.isPresto(),
            spinnerTriggers: [
                sport.action.GET_VIRTUAL_BETSLIPS,
                sport.action.GET_SETTLED_BETSLIPS,
                sport.action.GET_JACKPOT_BETSLIPS,
                sport.action.GET_PENDING_BETSLIPS,
            ],
        };
    },
    computed: {
        ...mapState({
            currency: (state) => state.platform.settings.currency,
            error: (state) => state.sport.myBets.error,
            betslipOpen: (state) => state.ui.betslipOpen,
        }),
        ...mapGetters({
            jackpot: sport.getter.GET_JACKPOT_BETS,
            settled: sport.getter.GET_SETTLED_BETS,
            pending: sport.getter.GET_PENDING_BETS,
            virtual: sport.getter.GET_VIRTUAL_BETS,
            isAuthenticated: auth.getter.IS_AUTHENTICATED,
            myBetsMenu: generalGetter.GET_MY_BETS_MENU,
            isLoading: coreGetter.IS_LOADING,
        }),
        itemsToTake() {
            if (this.isPreview) return DEFAULT_PREVIEW_ITEMS_PAGE;
            return REQUEST_ITEMS_PACE[this.currentSelector.key] || REQUEST_ITEMS_PACE.default;
        },
        isGetMoreData() {
            return getObjectField(this.mapDataByBetsType[this.currentSelector.key], 'data.items', []).length;
        },
        mapDataByBetsType() {
            const defaultValue = { items: [], hasMore: false };
            return {
                [myBetsType.SETTLED]: {
                    action: sport.action.GET_SETTLED_BETSLIPS,
                    data: getObjectField(this, myBetsType.SETTLED, defaultValue),
                },
                [myBetsType.VIRTUAL]: {
                    action: sport.action.GET_VIRTUAL_BETSLIPS,
                    data: getObjectField(this, myBetsType.VIRTUAL, defaultValue),
                },
                [myBetsType.PENDING]: {
                    action: sport.action.GET_PENDING_BETSLIPS,
                    data: getObjectField(this, myBetsType.PENDING, defaultValue),
                },
                [myBetsType.JACKPOT]: {
                    action: sport.action.GET_JACKPOT_BETSLIPS,
                    data: getObjectField(this, myBetsType.JACKPOT, defaultValue),
                },
            };
        },
    },
    methods: {
        onSnifferLoadMore() {
            const isValidKey = Object.values(myBetsType).includes(this.currentSelector.key);
            if (!this.isPreview && getObjectField(this.mapDataByBetsType[this.currentSelector.key], 'data.hasMore', false) && isValidKey) {
                this.loadMoreItems(this.currentSelector.key);
            }
        },
        initiateContents() {
            this.$store.dispatch(sport.action.RESET_MY_BETS);
            this.$store.dispatch(this.mapDataByBetsType[this.currentSelector.key].action, { take: this.itemsToTake });
        },
        setMenu(menu) {
            if (this.isPreview) {
                this.setSection(menu.name);
                return;
            }
            this.$router.push({ ...this.$route, params: { section: menu.name } });
        },
        loadMoreItems(type) {
            const mapData = this.mapDataByBetsType[type];
            if (mapData && !this.isLoading(mapData.action)) {
                this.$store.dispatch(mapData.action, {
                    take: this.itemsToTake,
                    skip: mapData.data.items.length,
                });
            }
        },
        generateRequestAgruments() {
            const args = {};
            args.offset = this.offset;
            args.maxResults = this.offsetStep;
            this.offset += this.offsetStep;
            return args;
        },
        setSection(section) {
            let defaultSection, currentSection;
            for (const item of this.myBetsMenu) {
                if (item.name === section) {
                    currentSection = item;
                    break;
                } else if (item.default) {
                    defaultSection = item;
                }
            }
            this.currentSelector = currentSection || defaultSection;
            this.initiateContents();
        },
        viewAllButtonClick(section) {
            this.betslipOpen && this.$store.dispatch(action.TOGGLE_BETSLIP_STATE);
            this.$gtm.query({
                event: 'view_all_button_click',
                section,
                isPreview: this.isPreview,
                isAuthenticated: this.isAuthenticated,
            });
        },
    },
    watch: {
        '$route.params.section': {
            immediate: true,
            handler(section, prevSection) {
                this.isRedirectToSettledAvailable = !prevSection;
                this.setSection(section);
            },
        },
        'pending.items'(pending) {
            if (!pending) {
                return;
            }

            if (!pending.length && this.isRedirectToSettledAvailable) {
                this.$router.push({ ...this.$route, params: { section: myBetsType.SETTLED } });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.request-offer {
    align-items: center;
    border: 1px solid $dark-grey;
    display: flex;
    font-size: 14px;
    justify-content: space-between;
    line-height: 16px;
    padding: 8px;

    &.error {
        border-color: $error-red;
    }

    &.warn {
        border-color: $golden-brown;
    }

    .offer-message {
        flex: 1;
        font-weight: normal;

        .spinner {
            line-height: initial;
        }
    }

    .offer-amount {
        color: $cashout-offer-amount-color;
        font-weight: 700;
    }

    .offer-button {
        flex: 1;
        font-size: 14px;
        font-weight: 700;
        white-space: nowrap;
    }

    @include only_mini {
        align-items: flex-start;
        flex-direction: column;

        .offer-button {
            margin-top: 10px;
            width: 100%;
        }
    }
}
.load-more-spinner {
    position: static;
    padding: 16px 0;
}

.spinner-wrapper {
    position: relative;
}
</style>
